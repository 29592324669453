// import React from 'react'
// import { BsClipboardCheckFill } from "react-icons/bs";

// import { BsFillChatLeftTextFill } from "react-icons/bs";


// const Section = ()=>{
//     return(
//         <>
//         <div className='row'>
//         <div className='col-lg-1'>
// </div>
//             <div className='col-lg-4 p-3'>
//                 <h3 style={{fontWeight:'bold',textAlign:'left'}}>Providing you with a safe space to heal.</h3>
//                 <p style={{textAlign:'left'}} className='mt-3'>
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.<br/><br/>
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.

//                 </p>
//                 </div>
//             <div className='col-lg-3'>
//                 <div className='p-3' style={{border:'1px solid rgb(0 0 0 / 3%)',borderRadius:'5px',backgroundColor:'rgb(0 0 0 / 3%)'}}>
//                     <center><BsFillChatLeftTextFill style={{fontSize:'36px',color:'#653399'}}/></center>
//                     <h3 className='mt-3' style={{textAlign:'center'}}>Consultations</h3>
//                     <p  style={{textAlign:'center'}}>Lorem ipsum dolor sit amet, <br/> consectetur adipiscing elit.
//                     Ut elit tellus, <br/>luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
//                 </div>
//             </div>
//             <div className='col-lg-3'>
//                 <div className='p-3' style={{border:'1px solid rgb(0 0 0 / 3%)',borderRadius:'5px',backgroundColor:'rgb(0 0 0 / 3%)'}}>
//                     <center><BsClipboardCheckFill style={{fontSize:'36px',color:'purple'}}/></center>
//                     <h3 className='mt-3'  style={{textAlign:'center'}}>Therapy</h3>
//                     <p  style={{textAlign:'center'}}>Lorem ipsum dolor sit amet, <br/> consectetur adipiscing elit.
//                     Ut elit tellus, <br/>luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
//                 </div>
//             </div>


//         </div>
//         </>
//     )
// }

// export default Section


import React, { useState, useEffect } from 'react';
import { BsClipboardCheckFill, BsFillChatLeftTextFill } from 'react-icons/bs';
import './Section.css'
const Section = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.animate-section');
      if (element) {
        const top = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (top < windowHeight - 100) {
          setAnimate(true);
        } else {
          setAnimate(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`row animate-section ${animate ? 'fade-in' : ''}`}>
        {/* ... Your existing content ... */}
        <div className='row p-2 mt-5'>
        <div className='col-lg-1'>
</div>
            <div className='col-lg-4 p-3 mb-5'>
              <small style={{color:'#4866BC',textAlign:'left'}}>Duration</small>
                <h3 style={{fontWeight:'bold',textAlign:'left'}}>Flexible Therapy Sessions Tailored to Your Needs</h3>
                <p style={{textAlign:'left'}} className='mt-3'>
                At  Shree Dhrithi PatashaalaOPC, we offer versatile therapy options to fit your child's journey. Whether you prefer Hour-Based sessions for convenience or Full-Time-Based sessions for comprehensive care, our tailored approach ensures your child's emotional growth and resilience, setting them on the path to a brighter future.

                </p>
                </div>
            <div className='col-lg-3 mb-5'>
                <div className='p-3' style={{border:'1px solid rgb(0 0 0 / 3%)',borderRadius:'5px',backgroundColor:'rgb(0 0 0 / 3%)',boxShadow:'0px 30px 60px 0px rgba(0, 0, 0, 0.1)'}}>
                    <center><BsFillChatLeftTextFill style={{fontSize:'36px',color:'#653399'}}/></center>
                    <h3 className='mt-3' style={{textAlign:'center'}}>Hour Based Session</h3>
                    <p  style={{textAlign:'center'}}>
                    "At our therapy center, we understand that each individual's journey towards healing and personal 
                growth is unique. That's why we offer duration hour-based therapy sessions. 
                  "</p>
                </div>
            </div>
            <div className='col-lg-3 mb-5'>
                <div className='p-3' style={{border:'1px solid rgb(0 0 0 / 3%)',borderRadius:'5px',backgroundColor:'rgb(0 0 0 / 3%)',boxShadow:'0px 30px 60px 0px rgba(0, 0, 0, 0.1)'}}>
                    <center><BsClipboardCheckFill style={{fontSize:'36px',color:'#653399'}}/></center>
                    <h3 className='mt-3'  style={{textAlign:'center'}}>Full Time Session</h3>
                    <p  style={{textAlign:'center'}}> "During the full-time session, you can fully immerse yourself in the therapeutic process, 
                    dedicating uninterrupted time to explore your thoughts, emotions, and life experiences."</p>
                </div>
            </div>


        </div>
      </div>
    </>
  );
};

export default Section;

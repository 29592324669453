import React from "react";
import Abt from "./Abt";

import core from "../image/core.png";
import im1 from "../image/im1.png";
import im2 from "../image/im2.png";
import im3 from "../image/im3.png";
import im4 from "../image/im4.png";
import im5 from "../image/im5.png";
import im6 from "../image/im6.png";
import im7 from "../image/im7.png";
import im8 from "../image/im8.png";
import im9 from "../image/im9.png";
import im10 from "../image/im10.png";
import im11 from "../image/im11.png";
import im16 from "../image/im16.png";
import im13 from "../image/im13.png";
import im14 from "../image/im14.png";
import vdther from '../image/vdther.mp4'





import Wave from "../base/Wave";
import VisionHome from "./VisionHome";
import { isMobile } from "react-device-detect";
import "./About.css";

const mobov = {
  position: "absolute",
  top: " 20%",
  // left: '40%',
  // transform:' translate(-50%, -50%)',
  textAlign: "center",
  color: "white",
};
const deskov = {
  position: "absolute",
  top: " 50%",
  left: "50%",
  transform: " translate(-50%, -50%)",
  textAlign: "center",
  color: "white",
};
const mobi = {
  width: "100%",
  height: "300px",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
  borderRadius: "10px",
};
const deski = {
  width: "80%",
  height: "500px",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
  borderRadius: "10px",
};
const mobs = { height: "500px" };
const desks = {};
const About = () => {
  return (
    <>
      <Wave />
      {/* <SecAbout/> */}

      {/* <Abt/> */}
      {/* <VisionHome/> */}

      <div
        className="row  p-2 mt-3"
        style={{ backgroundColor: "rgb(243 207 198 / 3%)" }}
      >
        <div className="col-lg-6">
          <img src={core} style={isMobile ? mobi : deski} className="mt-5" />
        </div>
        <div className="col-lg-6">
          <p
            style={{ fontSize: "20px", color: "#653399", textAlign: "left" }}
            className="mt-2"
          >
            our values
          </p>
          <h3 style={{ fontSize: "30px", textAlign: "left" }}>
            Guided by Values, Uniting for Impact: Our Foundation for Change
          </h3>

          <p
            className=" p-2"
            style={{
              textAlign: "left",
              border: "1px solid white",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
              borderRadius: "10px",
            }}
          >
            <b>Compassion and Empathy</b>:
            <br /> We deeply care about the well-being of others and approach
            every interaction with empathy and understanding. Our commitment to
            compassion drives us to be attentive and responsive to the needs of
            individuals and communities, fostering a sense of belonging and
            support.
          </p>
          <p
            className=" p-2"
            style={{
              textAlign: "left",
              border: "1px solid white",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
              borderRadius: "10px",
            }}
          >
            <b>Inclusivity and Diversity</b>:
            <br />
            We believe in the power of inclusivity and celebrate the richness of
            diversity. We respect and honor the unique backgrounds,
            perspectives, and experiences of all, recognizing that diversity
            strengthens our collective journey towards a better world.
          </p>
          <p
            className=" p-2"
            style={{
              textAlign: "left",
              border: "1px solid white",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
              borderRadius: "10px",
            }}
          >
            <b>Excellence and Innovation</b>:
            <br /> Striving for excellence in all we do, we continually seek
            innovative solutions to address complex challenges. We embrace
            creativity and embrace new ideas, pushing the boundaries of what's
            possible to maximize the positive impact of our efforts.
          </p>

          <p
            className=" p-2"
            style={{
              textAlign: "left",
              border: "1px solid white",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
              borderRadius: "10px",
            }}
          >
            <b>Playful Healing:</b>
            <br /> We believe in the power of play as a transformative tool for
            emotional growth. Our therapists use creative and engaging
            techniques to help children navigate challenges and express
            themselves.
          </p>

          <p
            className=" p-2"
            style={{
              textAlign: "left",
              border: "1px solid white",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 30px 60px 0px",
              borderRadius: "10px",
            }}
          >
            <b>Joyful Progress:</b>
            <br /> We celebrate every milestone and achievement, fostering an
            environment where children find joy in their progress, no matter how
            big or small.
          </p>
          {/* <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button> */}
        </div>
      </div>

      <div
        className="row  p-2 mt-3"
        style={{ backgroundColor: "rgb(243 207 198 / 3%)" }}
      >
        <div
          className="background-image-container"
          style={isMobile ? mobs : desks}
        >
          <div style={isMobile ? mobov : deskov}>
            <h3 className="mb-3" style={{ fontWeight: "bold" }}>
              HEALTH CARE
            </h3>
            <h5>
              Caring for You, Enriching Lives: Our Commitment to Health and
              Wellness
            </h5>
            <p>
              Our team of dedicated healthcare professionals, including doctors,
              nurses, specialists, and support staff, are driven by a shared
              mission to make a positive impact on the lives of our patients.
            </p>
          </div>
        </div>
      </div>

<div className="row">
<h3>Our Gallery</h3>
    <div className="col-lg-4"></div>
    <div className="col-lg-4">
    <video controls style={{width:'100%',height:'300px'}}>
        <source src={vdther} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
           
    </div>
</div>
      <div className="row">
        
        <div className="col-lg-2"></div>

        <div className="col-lg-8">
         
         
          <div className="row m-3">
            <div className="col-lg-3">
              {" "}
              <img src={im11} />
            </div>

            <div className="col-lg-3">
              {" "}
              <img src={im16} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im13} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im14} />
            </div>
          </div>
          <div className="row m-3">
            <div className="col-lg-3">
              <img src={im5} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im3} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im8} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im10} />
            </div>
          </div>

          <div className="row m-3">
            <div className="col-lg-3">
              {" "}
              <img src={im2} />
            </div>

            <div className="col-lg-3">
              {" "}
              <img src={im6} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im7} />
            </div>
            <div className="col-lg-3">
              {" "}
              <img src={im9} />
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default About;

import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from '../image/logo.png'

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div className="row d-lg-none " >
    <div className='row d-flex mb-5 '  >
    <div><Link to="/">
    <img src={logo} alt="logo" style={{ height: "50px",width:'50%',position:'absolute',left:'20px',top:'10px' }} />{" "}

       {/* <p style={{position:'absolute',left:'20px',top:'10px'}}>logo</p> */}
        {/* <img src={ani} style={{width:'150px',height:'50px',position:'absolute',left:'20px',top:'10px'}}/> */}
        </Link></div>
    <div>
      {/* Menu Icon */}
      <div onClick={handleToggleSidebar} style={{position:'absolute',right:'10px',top:'20px',color:'black !important',zIndex:'1',width:'50px'}} >
        {isOpen ? (
          <AiOutlineClose className="menu-icon" style={{width:'50px',height:'25px'}}/>
        ) : (
          <AiOutlineMenu className="menu-icon" style={{width:'50px',height:'25px'}}/>
        )}
      </div>

      {/* Sidebar Content */}
      {isOpen && (
        <div className="sidebar" style={{marginBottom:'-45px',backgroundColor:'rgb(222 226 230 / 2%)'}}>
          {/* Sidebar content goes here */}
          <div className=" p-3 w-100">
            <center className="mt-5">
              <Link
                className="navLink mt-1"
                to="/"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  HOME
                </b>
              </Link>
            </center>
            <center className="mt-2">
              <Link
                className="navLink mt-1"
                to="/about"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  ABOUT US
                </b>
              </Link>
            </center>
            <center className="mt-2">
              <Link
                className="navLink mt-1"
                to="/services"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  SERVICES
                </b>
              </Link>
            </center>
            <center className="mt-2">
              <Link
                className="navLink mt-1"
                to="/media"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  MEDIA
                </b>
              </Link>
            </center>
            <center className="mt-2">
              <Link
                className="navLink mt-1"
                to="/contact"
                onClick={handleMenuItemClick}
              >
                <b className="text-dark" style={{ fontSize: "14px" }}>
                  CONTACT US
                </b>
              </Link>
            </center>
          
          </div>
        </div>
      )}
    </div>
    </div>
    </div>
  );
};

export default MobileSidebar;




import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import logo from '../image/logo.png'
const mob={
  display: 'block'
}
const desk={
  display:'flex'

}

function Nav1() {
  return (
    <Navbar  expand="lg" className="sticky-top" style={{backgroundColor:'white'}}>
      <Container fluid>
        <Navbar.Brand href="/">
        
          <img src={logo} alt="logo" style={{ height: "50px",width:'100%' }} />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <Form  style={isMobile?mob:desk}>
            <Link className="navLink mt-1" to="/">
            <b style={{color:'#653399'}}>  HOME</b>
            </Link>
            <Link className="navLink mt-1" to="/about">
             <b style={{color:'#653399'}}> ABOUT US</b>
            </Link>
            <Link className="navLink mt-1" to="/services">
             <b style={{color:'#653399'}}> SERVICES</b>
            </Link>

            <Link className="navLink mt-1" to="/media">
             <b style={{color:'#653399'}}> MEDIA</b>
            </Link>
            {/* <Link className="navLink mt-1" to="/blogs">
            <b style={{color:'#653399'}}>  BLOGS</b>
            </Link> */}

            <Link className="navLink mt-1" to="/contact">
            <b style={{color:'#653399'}}>  CONTACT US</b>
            </Link>
           
            {/* <Link className="navLink mt-1" to="/">
                    Product
                </Link>
                <Link className="navLink mt-1" to="/cart">
                    Cart
                </Link>
                <span className="cartCount mt-1"><span><img src={cart} style={{width:'60px',height:'30px'}}/ ></span>Cart items: {items.length}</span> */}
            {/* <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button> */}
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Nav1;

import React from 'react';
import { FaPhone } from 'react-icons/fa';
import './CallIcon.css'
import calling from '../image/calling.png'
const CallIcon = () => {
  const phoneNumber = '9008361473'; // Replace with the actual phone number

  const callUrl = `tel:${phoneNumber}`;

  return (
    <div className="call-container">
    <a href={callUrl}>
      {/* <FaPhone /> */}
      <img src={calling} style={{width:'70px'}}/>
    </a>
    </div>
  );
};

export default CallIcon;

import React from 'react'
import './MediaPage2.css'
import { BsFillPeopleFill,BsFingerprint ,BsFastForwardFill} from "react-icons/bs";
import aboutim from '../image/aboutim.jpg'
import playing from '../image/playing.png'
import yoga from '../vedios/yoga.mp4'
import { isMobile } from 'react-device-detect';
import yog from '../vedios/yog.mp4'


const mobim={height:'300px',width:'100%'}
const deskim={height:'600px',width:'100%'}
const mobimg={width:'100%',height:'250px'}
const deskimg={width:'100%',height:'500px'}
const mobi={width:'400px',height:'150px'}
const deski={width:'400px',height:'200px'}
const MediaPage2 = ()=>{
    return(
        <>
        
        <div
  data-elementor-type="wp-post"
  data-elementor-id={366}
  className="elementor elementor-366"
>

  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-4777ede elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="4777ede"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7aafba"
        data-id="f7aafba"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-296d0d3 elementor-widget elementor-widget-heading"
            data-id="296d0d3"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}"
                }}
              />
              <h2 className="elementor-heading-title elementor-size-default">
            <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}>   Fun Yoga for Kids</i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-f3d8465 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="f3d8465"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Stretching and Relaxation
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1a65318 elementor-widget elementor-widget-text-editor"
            data-id="1a65318"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> this engaging video, our experienced children's therapists guide young minds and bodies through a series of playful and gentle yoga exercises. Designed specifically for children, this yoga session encourages flexibility, balance, and relaxation while fostering a positive connection to movement.</p>
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              Children will love joining our animated characters on a journey of stretching, breathing, and finding their inner calm</p>
             
            </div>
          </div>
         
          <div
            className="elementor-element elementor-element-e563791 elementor-widget elementor-widget-text-editor" style={{color:'#653399'}}
            data-id="e563791"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <i style={{color:'black',fontSize:'22px'}}>
              Interactive Exercise Adventure - Let's Get Moving!”
              </i>{" "}
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-19ccd47 animated-slow animated fadeInRight"  style={{color:'#653399'}}
        data-id="19ccd47"
        data-element_type="column"
        data-settings='{"animation":"fadeInRight"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-b601bcd elementor-widget elementor-widget-image "
            data-id="b601bcd"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container ">
           
            <video controls style={{width:'100%'}}>
        <source src={yog} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
           
            </div>
          </div>
          <div
            className="elementor-element elementor-element-bdc7a82 elementor-widget elementor-widget-spacer"
            data-id="bdc7a82"
            data-element_type="widget"
            data-widget_type="spacer.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-column .elementor-spacer-inner{height:var(--spacer-size)}.e-con{--container-widget-width:100%}.e-con-inner>.elementor-widget-spacer,.e-con>.elementor-widget-spacer{width:var(--container-widget-width,var(--spacer-size));--align-self:var(--container-widget-align-self,initial);--flex-shrink:0}.e-con-inner>.elementor-widget-spacer>.elementor-widget-container,.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer,.e-con>.elementor-widget-spacer>.elementor-widget-container,.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer{height:100%}.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner,.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner{height:var(--container-widget-height,var(--spacer-size))}"
                }}
              />{" "}
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner" />
              </div>
            </div>
          </div>
          {/* <div
            className="elementor-element elementor-element-c2a22ef elementor-widget elementor-widget-elementskit-funfact"  style={{backgroundColor:'red'}}
            data-id="c2a22ef"
            data-element_type="widget"
            data-widget_type="elementskit-funfact.default"
          >
            <div className="elementor-widget-container">
              <div className="ekit-wid-con">
                <div className="elementskit-funfact text-center     ">
                  <div className="elementskit-funfact-inner position_top">
                    <div className="funfact-icon">
                      {" "}
                      <i />
                    </div>
                    <div className="funfact-content">
                      <div className="number-percentage-wraper">
                        <span
                          className="number-percentage"
                          data-value={13}
                          data-animation-duration={3500}
                          data-style="static"
                        >
                          13
                        </span>
                        <span className="super">Th</span>
                      </div>
                      <h3 className="funfact-title">Years Experience</h3>{" "}
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </section>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-b57e4b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="b57e4b3"
    data-element_type="section"
    data-settings='{"background_background":"classic"}'
  >
    <div className="elementor-background-overlay" />
    <div className="elementor-container elementor-column-gap-default">
     <div className='p-2' style={{height:'500px'}} >
        <center>
            <h3 className='text-white mt-2' style={{backdropFilter: 'blur(20px)'}}>
"Mindful Moments: Breathing and Mindfulness for Kids"</h3>

<div className='col-lg-8'>
            <video controls style={{width:'100%'}}>
        <source src={yoga} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
            </div>
        </center>
     </div>
     
    
     
    </div>
  </section>

  
 
 
</div>

        </>
    )
}

export default MediaPage2
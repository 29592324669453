import React from 'react';
import whats from '../image/whats.png';
import './WhatsApp.css'; // Import the CSS file for styling

const WhatsApp = () => {
  const phoneNumber = '9008361473'; // Replace with the actual phone number
  const message = encodeURIComponent('Hello from my app!'); // URL encode the message

  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

  return (
    <div className="whatsapp-container">
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        <img src={whats} style={{ width: '80px' }} alt="WhatsApp Icon" />
      </a>
    </div>
  );
};

export default WhatsApp;


import React from 'react'
import './ServicesCard.css'
import { BsFillPeopleFill,BsFingerprint ,BsFastForwardFill} from "react-icons/bs";
import behave from '../image/behave.jpg'
import speech from '../image/speech.jpg'
import parent from '../image/parent.jpg'
import occu from '../image/occu.jpg'
import school from '../image/school.jpg'

import group from '../image/group.jpg'
import { isMobile } from 'react-device-detect';


const mobim={height:'250px',width:'100%'}
const deskim={height:'500px',width:'100%'}
const mobimg={width:'100%',height:'250px'}
const deskimg={width:'100%',height:'500px'}

const ServicesCard = ()=>{
    return(
        <>
        
        <div
  data-elementor-type="wp-post"
  data-elementor-id={366}
  className="elementor elementor-366"
>

  <section style={{marginTop:'-150px'}}
    className="elementor-section elementor-top-section elementor-element elementor-element-4777ede elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="4777ede"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7aafba"
        data-id="f7aafba"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-296d0d3 elementor-widget elementor-widget-heading"
            data-id="296d0d3"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}"
                }}
              />
              <h2 className="elementor-heading-title elementor-size-default">
            <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}>   Behavior therapy</i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-f3d8465 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="f3d8465"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Rewriting patterns, embracing growth. Behavior therapy paves the way for positive change
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1a65318 elementor-widget elementor-widget-text-editor"
            data-id="1a65318"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> In behavior therapy we teach children using techniques and protocols and help them understand the task or situation in better way. It also teaches them to behave in situations. </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>The main goal is to reinforce the expected and desirable behavior from the child. The main objective is to remove or modify existing symptoms and enhance or promote positive behavior growth and development. </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              Behavioral therapy is the most effective way of therapy which helps in most areas.

              </p>
             
            </div>
          </div>
         
          {/* <div
            className="elementor-element elementor-element-e563791 elementor-widget elementor-widget-text-editor" style={{color:'#653399'}}
            data-id="e563791"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <i style={{color:'black',fontSize:'20px'}}>
                “We help children in building skills in areas such as motor skills play, communication, understanding, school concepts, social skills, and academic skills.”
              </i>{" "}
            </div>
          </div> */}
        </div>
      </div>
      <div 
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-19ccd47 animated-slow animated fadeInRight"  style={{color:'#653399'}}
        data-id="19ccd47"
        data-element_type="column"
        data-settings='{"animation":"fadeInRight"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}
            
            data-id="b601bcd"
            data-element_type="widget"
            data-widget_type="image.default"
          >
           
              <img
                // decoding="async"
                // width={800}
                // height={900}
src={behave}  style={isMobile?mobim:deskim}             
              />{" "}
           
          </div>
         
         
        </div>
      </div>
    </div>
  </section>

  <section style={{marginTop:'-150px'}}
    className="elementor-section elementor-top-section elementor-element elementor-element-400bbe7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="400bbe7"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8031c76 animated-slow animated fadeInLeft"
        data-id="8031c76"
        data-element_type="column"
        data-settings='{"animation":"fadeInLeft"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
           style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}
            data-id="4df6451"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
           
                 <img src={speech} style={isMobile?mobimg:deskimg}
              />{" "}
            </div>
          </div>
         
        
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4ce8b0b"
        data-id="4ce8b0b"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-7ebb03b elementor-widget elementor-widget-heading"
            data-id="7ebb03b"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
              <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}> 
              Speech therapy
              </i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-b34f2d0 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="b34f2d0"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Finding Your Voice, Embracing Your Potential.
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-5593f33 elementor-widget elementor-widget-text-editor"
            data-id="5593f33"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
            <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
            The goal of speech therapy is to help children understand improve pronunciation, strengthening muscles, and learn to speak correctly. </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
               It is an effective way that improves the ability to talk and understand other language skills. </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              it also helps in memory and ability to solve problems.</p>


              
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
  </section>

  <section style={{marginTop:'-150px'}}
    className="elementor-section elementor-top-section elementor-element elementor-element-4777ede elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="4777ede"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7aafba"
        data-id="f7aafba"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-296d0d3 elementor-widget elementor-widget-heading"
            data-id="296d0d3"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}"
                }}
              />
              <h2 className="elementor-heading-title elementor-size-default">
            <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}> Parent training</i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-f3d8465 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="f3d8465"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Your Journey to Effective Parenting Starts Here
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1a65318 elementor-widget elementor-widget-text-editor"
            data-id="1a65318"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> 
              Parent training is one the main programme we focus. Usually, parents find difficult to understand or work with children.  </p>
          
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              It would be 20-day programme where the parent will be trained for 2 hours along with their kid. 

              </p>
             
            </div>
          </div>
         
     
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-19ccd47 animated-slow animated fadeInRight"  style={{color:'#653399'}}
        data-id="19ccd47"
        data-element_type="column"
        data-settings='{"animation":"fadeInRight"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
         style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}
            data-id="b601bcd"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
              <img
                // decoding="async"
                // width={800}
                // height={900}
src={parent}  style={isMobile?mobim:deskim}             
              />{" "}
            </div>
          </div>
        
         
        </div>
      </div>
    </div>
  </section>

  <section style={{marginTop:'-150px'}}
    className="elementor-section elementor-top-section elementor-element elementor-element-400bbe7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="400bbe7"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8031c76 animated-slow animated fadeInLeft"
        data-id="8031c76"
        data-element_type="column"
        data-settings='{"animation":"fadeInLeft"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
           style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}
            data-id="4df6451"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
           
                 <img src={group} style={isMobile?mobimg:deskimg}
              />{" "}
            </div>
          </div>
        
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4ce8b0b"
        data-id="4ce8b0b"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-7ebb03b elementor-widget elementor-widget-heading"
            data-id="7ebb03b"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
              <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}> 
              Group therapy

              </i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-b34f2d0 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="b34f2d0"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Healing Together, Thriving as One.
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-5593f33 elementor-widget elementor-widget-text-editor"
            data-id="5593f33"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
            <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
            Group therapy main goal is to help the children to communicate and understand how to ask share and help others. Socialization is the main goal in group therapy generalization also plays a very important role. 
            </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              Group therapy, a transformative and compassionate space, brings individuals together on a shared journey of healing and growth. In the supportive company of like-minded peers, participants find solace and understanding, knowing they are not alone in their struggles. </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              Led by skilled therapists, group therapy sessions provide a platform for open expression, genuine connections, and profound insights.</p>


              
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
  </section>

  <section style={{marginTop:'-150px'}}
    className="elementor-section elementor-top-section elementor-element elementor-element-4777ede elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="4777ede"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7aafba"
        data-id="f7aafba"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-296d0d3 elementor-widget elementor-widget-heading"
            data-id="296d0d3"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}"
                }}
              />
              <h2 className="elementor-heading-title elementor-size-default">
            <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}> School shadow training</i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-f3d8465 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="f3d8465"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Unlocking Minds, Empowering Futures
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1a65318 elementor-widget elementor-widget-text-editor"
            data-id="1a65318"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> 
              The main role of shadow teacher is to help the children to adjust to environment make them comfortable and help them understand classroom rules.   </p>
          
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              They will help the children in communication and socialization purpose.

              </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              They will record data and work on the target. With the help of school shadow, the child will know how to be in class. The final goal would be to make the child independent in class.


              </p>
             
            </div>
          </div>
         
      
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-19ccd47 animated-slow animated fadeInRight"  style={{color:'#653399'}}
        data-id="19ccd47"
        data-element_type="column"
        data-settings='{"animation":"fadeInRight"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}            data-id="b601bcd"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
              <img
                // decoding="async"
                // width={800}
                // height={900}
src={school}  style={isMobile?mobim:deskim}             
              />{" "}
            </div>
          </div>
         
         
        </div>
      </div>
    </div>
  </section>


  <section style={{marginTop:'-150px'}}
    className="elementor-section elementor-top-section elementor-element elementor-element-400bbe7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="400bbe7"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8031c76 animated-slow animated fadeInLeft"
        data-id="8031c76"
        data-element_type="column"
        data-settings='{"animation":"fadeInLeft"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
           style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}
            data-id="4df6451"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
           
                 <img src={occu} style={isMobile?mobimg:deskimg}
              />{" "}
            </div>
          </div>
         
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4ce8b0b"
        data-id="4ce8b0b"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-7ebb03b elementor-widget elementor-widget-heading"
            data-id="7ebb03b"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
              <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}> 
              Occupational therapy

              </i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-b34f2d0 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="b34f2d0"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
              Nurturing Potential, Empowering Futures: Special Education Therapy
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-5593f33 elementor-widget elementor-widget-text-editor"
            data-id="5593f33"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
            <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
            The main goal is to improve everyday skills which will help them to be more independent
            </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              It will also help in attention skills, self-care and learning strategies. </p>
              <p className='p-2' style={{backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              Calming activities gives an awareness of their own body and self and helps them regulate sensory input and to participate in social interactions.
</p>


              
            </div>
          </div>
         
          
        </div>
      </div>
    </div>
  </section>


 
</div>

        </>
    )
}

export default ServicesCard
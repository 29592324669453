import React from 'react'
import './Wave.css'
import { BsClipboardCheckFill, BsFillChatLeftTextFill } from 'react-icons/bs';

import { isMobile } from 'react-device-detect';



const mobt={marginTop:'112px'}
const deskt={}



const Wave = ()=>{
    return(
        <>
     <>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-dark-grayscale">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR type="table" tableValues="0 0.49803921568627" />
          <feFuncG type="table" tableValues="0 0.49803921568627" />
          <feFuncB type="table" tableValues="0 0.49803921568627" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-grayscale">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR type="table" tableValues="0 1" />
          <feFuncG type="table" tableValues="0 1" />
          <feFuncB type="table" tableValues="0 1" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-purple-yellow">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR
            type="table"
            tableValues="0.54901960784314 0.98823529411765"
          />
          <feFuncG type="table" tableValues="0 1" />
          <feFuncB
            type="table"
            tableValues="0.71764705882353 0.25490196078431"
          />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-blue-red">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR type="table" tableValues="0 1" />
          <feFuncG type="table" tableValues="0 0.27843137254902" />
          <feFuncB
            type="table"
            tableValues="0.5921568627451 0.27843137254902"
          />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-midnight">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR type="table" tableValues="0 0" />
          <feFuncG type="table" tableValues="0 0.64705882352941" />
          <feFuncB type="table" tableValues="0 1" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-magenta-yellow">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR type="table" tableValues="0.78039215686275 1" />
          <feFuncG type="table" tableValues="0 0.94901960784314" />
          <feFuncB
            type="table"
            tableValues="0.35294117647059 0.47058823529412"
          />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-purple-green">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR
            type="table"
            tableValues="0.65098039215686 0.40392156862745"
          />
          <feFuncG type="table" tableValues="0 1" />
          <feFuncB type="table" tableValues="0.44705882352941 0.4" />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 0 0"
    width={0}
    height={0}
    focusable="false"
    role="none"
    style={{
      visibility: "hidden",
      position: "absolute",
      left: "-9999px",
      overflow: "hidden"
    }}
  >
    <defs>
      <filter id="wp-duotone-blue-orange">
        <feColorMatrix
          colorInterpolationFilters="sRGB"
          type="matrix"
          values=" .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 .299 .587 .114 0 0 "
        />
        <feComponentTransfer colorInterpolationFilters="sRGB">
          <feFuncR type="table" tableValues="0.098039215686275 1" />
          <feFuncG type="table" tableValues="0 0.66274509803922" />
          <feFuncB
            type="table"
            tableValues="0.84705882352941 0.41960784313725"
          />
          <feFuncA type="table" tableValues="1 1" />
        </feComponentTransfer>
        <feComposite in2="SourceGraphic" operator="in" />
      </filter>
    </defs>
  </svg>
  <a className="skip-link screen-reader-text" href="#content">
    Skip to content
  </a>
  <div
    data-elementor-type="header"
    data-elementor-id={40}
    className="elementor elementor-40 elementor-location-header"
  >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-a06606e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="a06606e"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
     
      </div>
    </section>
  </div>
  <div
    data-elementor-type="wp-post"
    data-elementor-id={1173}
    className="elementor elementor-1173"
  
    style={isMobile?mobt:deskt}
  >
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-a90a8d5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="a90a8d5"
      data-element_type="section"
      data-settings='{"background_background":"slideshow","shape_divider_bottom":"waves","shape_divider_bottom_negative":"yes","background_slideshow_gallery":[{"id":444,"url":"https:\/\/newkit.moxcreative.com\/mentheer\/wp-content\/uploads\/sites\/31\/2022\/09\/psychologist-listening-to-patient-at-session.jpg"},{"id":77,"url":"https:\/\/newkit.moxcreative.com\/mentheer\/wp-content\/uploads\/sites\/31\/2022\/09\/mental-health-and-counseling.jpg"},{"id":84,"url":"https:\/\/newkit.moxcreative.com\/mentheer\/wp-content\/uploads\/sites\/31\/2022\/09\/loving-black-couple-visiting-therapy-session-at-psychologist-39-s-office.jpg"}],"background_slideshow_loop":"yes","background_slideshow_slide_duration":5000,"background_slideshow_slide_transition":"fade","background_slideshow_transition_duration":500}'
    >
      <div
        className="elementor-background-slideshow swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal swiper-container-rtl"
        dir="rtl"
      >
        <div className="swiper-wrapper" style={{ transitionDuration: "0ms" }}>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate"
            data-swiper-slide-index={0}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(0px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
            data-swiper-slide-index={1}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(1863px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
            data-swiper-slide-index={2}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(3726px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate-next"
            data-swiper-slide-index={0}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(5589px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-prev"
            data-swiper-slide-index={1}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(7452px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-active"
            data-swiper-slide-index={2}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 1,
              transform: "translate3d(9315px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-next"
            data-swiper-slide-index={0}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(11178px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
            data-swiper-slide-index={1}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(13041px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
          <div
            className="elementor-background-slideshow__slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
            data-swiper-slide-index={2}
            style={{
              width: 1863,
              transitionDuration: "0ms",
              opacity: 0,
              transform: "translate3d(14904px, 0px, 0px)"
            }}
          >
            <div
              className="elementor-background-slideshow__slide__image"
              style={{
                backgroundImage:
                  'url("https://i0.wp.com/talkingcirclestherapy.com/wp-content/uploads/2021/09/therapist-for-children.jpeg?ssl=1")'
              }}
            />
          </div>
        </div>
        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        />
      </div>
      <div className="elementor-background-overlay" />
      <div
        className="elementor-shape elementor-shape-bottom"
        data-negative="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
	c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
	c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"
          />
        </svg>{" "}
      </div>
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2d8cd9f"
          data-id="2d8cd9f"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-bfc2bc7 elementor-section-content-middle elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="bfc2bc7"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4853afb"
                  data-id="4853afb"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    
                    <div
                      className="elementor-element elementor-element-2f92556 elementor-widget elementor-widget-heading animated fadeInUp"
                      data-id="2f92556"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp","_animation_delay":200}'
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h1 className="elementor-heading-title elementor-size-default text-white">
                       About us
                        </h1>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-c272ee7 animated-slow elementor-widget elementor-widget-heading animated fadeInDown"
                      data-id="c272ee7"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInDown","_animation_delay":300}'
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-heading-title elementor-size-default text-white">
                     <h3>A healthier choice for a healthier you.</h3>             
                               </div>{" "}
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-79b8972 elementor-mobile-align-justify elementor-widget elementor-widget-button animated bounceIn"
                      data-id="79b8972"
                      data-element_type="widget"
                      data-settings='{"_animation":"bounceIn","_animation_delay":400}'
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        {/* <div className="elementor-button-wrapper">
                          <a
                            href="#"
                            className="elementor-button-link elementor-button elementor-size-lg"
                            role="button"
                          >
                            <span className="elementor-button-content-wrapper">
                              <span className="elementor-button-text">
                                Get an Appointment
                              </span>
                            </span>
                          </a>
                        </div> */}
              

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  
  
{/*  
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-a56bf66 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="a56bf66"
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a9d03d9 animated fadeInLeft"
          data-id="a9d03d9"
          data-element_type="column"
          data-settings='{"animation":"fadeInLeft"}'
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-b4d5777 elementor-widget elementor-widget-heading"
              data-id="b4d5777"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Providing you with a safe space to heal.
                </h2>{" "}
              </div>
            </div>
            <div
              className="elementor-element elementor-element-eeced1e elementor-widget elementor-widget-text-editor"
              data-id="eeced1e"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                <p>
                Our mission is to create an environment where your child can express themselves freely, explore their emotions, and build the resilience needed to overcome challenges.
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1c50968"
          data-id="1c50968"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-ad63ecb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="ad63ecb"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div 
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d637f0c animated fadeInRight"
                  data-id="d637f0c"
                  data-element_type="column"
                  data-settings='{"background_background":"classic","animation":"fadeInRight","animation_delay":200}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated" style={{border:'1px solid rgb(0 0 0 / 3%)',borderRadius:'5px',backgroundColor:'rgb(0 0 0 / 3%)',boxShadow:'0px 30px 60px 0px rgba(0, 0, 0, 0.1)'}}>
                    <div
                      className="elementor-element elementor-element-24cc64b elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                      data-id="24cc64b"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container" >
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                          <center><BsFillChatLeftTextFill style={{fontSize:'36px',color:'purple'}}/></center>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h4 className="elementor-icon-box-title">
                              <span>Consultations </span>
                            </h4>
                            <p className="elementor-icon-box-description">
                            Personalized guidance for your child's well-being. Schedule a consultation now                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div 
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-0b1937f animated fadeInRight"
                  data-id="0b1937f"
                  data-element_type="column"
                  data-settings='{"background_background":"classic","animation":"fadeInRight","animation_delay":300}'
                >
                  <div className="elementor-widget-wrap elementor-element-populated" style={{border:'1px solid rgb(0 0 0 / 3%)',borderRadius:'5px',backgroundColor:'rgb(0 0 0 / 3%)',boxShadow:'0px 30px 60px 0px rgba(0, 0, 0, 0.1)'}}>
                    <div
                      className="elementor-element elementor-element-4336d4d elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                      data-id="4336d4d"
                      data-element_type="widget"
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                          <center><BsClipboardCheckFill style={{fontSize:'36px',color:'purple'}}/></center>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h4 className="elementor-icon-box-title">
                              <span>Therapy </span>
                            </h4>
                            <p className="elementor-icon-box-description">
                            Empower your child's growth through nurturing therapy. Contact us for a brighter future                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section> */}
   
   
  
  
   
  
  
  </div>
 
</>


        </>
    )
}

export default Wave
import React from 'react'
import consult from '../image/consult.png'
import mental from '../image/mental.png'
import cupping from '../image/cupping.png'
import physical from '../image/physical.png'
import back from '../image/back.png'
import aespot from '../image/aespot.png'
import plate from '../image/plate.png'
import quote from '../image/quote.png'
import visit from '../image/visit.png'
import fruit from '../image/fruit.png'



import { isMobile } from 'react-device-detect'




import './Blog.css'
const mobText = {border:'1px solid #D8BFD8',width:'50%',borderRadius:'0px',backgroundColor:'rgb(216 191 216 / 55%)',color:'#41464bde',fontSize:'18px'}
const deskText ={border:'1px solid #D8BFD8',width:'30%',borderRadius:'0px',backgroundColor:'rgb(216 191 216 / 55%)',color:'#41464bde',fontSize:'18px'}



const Blog = ()=>{
    return(
        <>
         <div>
          <img src={consult} style={{ width: '100%', height: '700px' }} />
          <div className="text-overlay">
        <h3>blog</h3>
        </div>
        </div>

        <div className='row p-3' style={{border:'1px solid #653399',backgroundColor:'#653399'}}>
            <div className='col-lg-4 text-white p-3'>
                <img src={mental} style={{width:'80px'}}/>
                <h3>Understanding Anxiety</h3>
                <p>Unraveling the Roots and Learning to Thrive</p>
            </div>
            <div className='col-lg-4 text-white p-3'>
                <img src={cupping}  style={{width:'80px'}}/>
                <h3>The Power of Self-Compassion</h3>
                <p> Embracing Kindness in Your Healing Journey</p>
            </div>
            <div className='col-lg-4 text-white p-3'>
                <img src={physical}  style={{width:'80px'}}/>
                <h3>Breaking Free from Negative Thought Patterns</h3>
                <p>Rewiring Your Mind for Positivity</p>
            </div>
        </div>

        <div className='row' style={{border:'1px solid white'}}>
        <div className='col-lg-2'></div>
            <div className='col-lg-4'>
                <div className='mt-3'>
<img src={back} style={{width:'300px',height:'300px',borderRadius:'150px',boxShadow:'rgb(0 0 0 / 11%) 0px 0px 5px 2px'}}/>
                </div>
            </div>
            <div className='col-lg-4'>
            <i className='mt-3' style={{color:'brown',fontSize:'28px'}}>therapy blog dummy content</i>
                <p className='mt-3'>The Role of Empathy in Therapy: Explore the importance of empathy in the therapeutic relationship and how it fosters healing.</p>
                <p>Managing Perfectionism: Offer strategies for coping with and reducing the impact of perfectionistic tendencies.</p>
                <p>Understanding and Addressing Anger: Discuss healthy ways to express and manage anger in therapy.</p>
                <p>The Power of Positive Psychology: Introduce positive psychology principles and how they can contribute to mental well-being.</p>
                <p>Cultivating Emotional Intelligence: Provide insights on how to develop emotional intelligence for improved self-awareness and empathy.</p>
                <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button>
            </div>
          
        </div>
        <div className='m-5'></div>
        <div
    className="d-none d-sm-block">
        <div className='row mt-5' style={{ border: '1px solid white',boxShadow:'rgb(0 0 0 / 11%) 0px 0px 5px 2px' }}>

  <div className='col-lg-1'></div>
  <div className='col-lg-2' style={{ position: 'relative',  marginTop: '-70px' }}>
    <img src={aespot} style={{ width: '100%', height: '300px',boxShadow:'rgb(0 0 0 / 11%) 0px 0px 5px 2px',borderRadius:'5px' }} />
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
      <p style={{ color: 'white', fontSize: '24px', fontWeight: 'bold', textShadow: '2px 2px 4px black' }}>Text Over Image 1</p>
    </div>
  </div>
  <div className='col-lg-2' style={{ position: 'relative', marginTop: '-70px' }}>
    <img src={plate} style={{ width: '100%', height: '300px',boxShadow:'rgb(0 0 0 / 11%) 0px 0px 5px 2px',borderRadius:'5px' }} />
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
      <p style={{ color: 'white', fontSize: '24px', fontWeight: 'bold', textShadow: '2px 2px 4px black' }}>Text Over Image 2</p>
    </div>
  </div>
  <div className='col-lg-5'>
    <h3 className='mt-5' style={{fontSize:'30px',color:'#41464bde'}}>"Unraveling the Path to Mental Well-being: Empowering Your Journey of Healing and Growth"</h3>
    <p>Through our blog posts, we aim to provide valuable insights, practical strategies, and thoughtful reflections on various aspects of mental health and personal growth</p>
  </div>
</div>
</div>

<div className='row mb-5'>
    <div className='col-lg-3'></div>
    <div className='col-lg-6'>
        <center>
            <img src={quote} style={{width:'80px'}}/>
        </center>
        <i className='mt-3' style={{color:'brown',fontSize:'20px'}}>Together, let us unravel the threads of healing, discovery, and growth, as we embark on this journey of transformation—one blog post at a time.</i>
        <p className='mt-3'>Whether you are seeking answers, inspiration, or simply a space to connect with others on a similar journey, our blog is here to accompany you. We invite you to explore the myriad topics 
            we present, knowing that each article is crafted with care and the hope that it may kindle a spark of insight or a newfound sense of strength within you.</p>
    </div>

    
</div>
<h3 style={{position:'absolute',left:'30%'}}>plan a visit</h3>

<div className='row'>
  <div className='col-lg-2'></div>
  <div className='col-lg-4 mt-5' style={{ position: 'relative',zIndex:'1' }}>
    <h3 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: 'white', fontSize: '16px', fontWeight: 'bold', background: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
    Feel free to modify and personalize the paragraph to align with your therapy center's unique approach, values, and services.
        </h3>
    <div>
      <img src={visit} style={{ width: '100%', height: '300px', borderRadius: '8px' }} />
    </div>
  </div>
  <div className='col-lg-4' style={{  position: 'absolute', right: '25%' }}>
   
    <div>
      <img src={fruit} style={{ width: '80%', height: '300px', borderRadius: '8px' }} />
    </div>
  </div>
</div>




       
        </>
    )
}

export default Blog
import React from "react";
import Map from './Map'
import ContactForm from "./ContactForm";
import aespot from '../image/aespot.png'
import WaveContact from "../base/WaveContact";

const Contact = () => {
//   document.title = "Hola9 - Contact Us";
  return (
    <>
    <WaveContact/>
    <div className="row p-3" style={{backgroundColor:'#80808008'}} >
      <div className="col-lg-1"></div>
      <div className="col-lg-5" style={{border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
        <h1 className="mt-5" style={{textAlign:'left'}}>Get in touch</h1>
        <p style={{textAlign:'left'}}>Have something to say or ask? We're all ears! Reach out to us and let's chat.</p>
        <hr className="m-3" style={{color:'black',width:'90%',margin:'auto'}}/>
        <div className="row">
          <div className="col-lg-6">
         <h2 style={{textAlign:'left',color:'#653399'}}> Head Office</h2>
          <p style={{textAlign:'left'}}>Raja Rajeshwari Nagar<br/>
Arekere Bannerghatta road Hennur Kalyan Nagar</p>
          </div>
          <div className="col-lg-6">
          <h2 style={{textAlign:'left',color:'#653399'}}> Email us</h2>
        <p style={{textAlign:'left'}}>Ksowmya402@gmail.com</p>
          </div>
        </div>
        <h2 style={{textAlign:'left',color:'#653399'}}>Call us</h2>
        <p style={{textAlign:'left'}}>Mobile Number : 9008361473</p>
      </div>
      <div className="col-lg-5"  style={{border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
      <Map/>
      </div>


    </div>
    
    
    </>
  );
};

export default Contact;
import React from 'react'
// import schoolshadow from '../image/schoolshadow.png'
// import behaviour from '../image/behaviour.png'
// import speechtherapy from '../image/speechtherapy.png'
// import grouptherapy from '../image/grouptherapy.png'
// import academic from '../image/academic.png'
// import commu from '../image/commu.png'
// import special from '../image/special.png'






import { isMobile } from 'react-device-detect'
import WaveService from '../base/WaveService'
import ServicesCard from './ServicesCard'


const mobText = {border:'1px solid #D8BFD8',width:'50%',borderRadius:'0px',backgroundColor:'rgb(216 191 216 / 55%)',color:'#41464bde',fontSize:'18px'}
const deskText ={border:'1px solid #D8BFD8',width:'30%',borderRadius:'0px',backgroundColor:'rgb(216 191 216 / 55%)',color:'#41464bde',fontSize:'18px'}

const mobText1 = {border:'1px solid #F3CFC6',width:'50%',borderRadius:'0px',backgroundColor:'rgb(243 207 198 / 33%)',color:'#41464bde',fontSize:'18px'}
const deskText1 ={border:'1px solid #F3CFC6',width:'30%',borderRadius:'0px',backgroundColor:'rgb(243 207 198 / 33%)',color:'#41464bde',fontSize:'18px'}

const Services = ()=>{
    const borderBottomColor = '#880085';
    const borderLeftColor = '#880085';
    const borderRightColor = '#880085';
    return(
        <>
        <WaveService/>
        {/* <h3 style={{fontSize:'40px',color:'#653399'}}>Our Services</h3> */}
        <ServicesCard/>
     
               
        {/* <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(243 207 198 / 20%)'}}>
            <div className='col-lg-7' style={{ borderBottom: `3px solid ${borderBottomColor}` }}>
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-2'>School Shadow</p>
            <h3 style={{fontSize:'30px',color:'#41464bde'}}>"Empowering Students through School Shadow Support"</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Navigating the educational environment can be challenging for some students.
                 Our "School Shadow" service offers personalized support for students, empowering them to thrive in their academic journey.
                  Our experienced therapists work closely with students, accompanying them to school and providing guidance in various aspects of their educational experience. 
                  By offering tailored assistance, we aim to enhance social skills, build confidence, and foster a positive learning environment.
                "</p>

                    <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button>
            </div>
            <div className='col-lg-5'>
                <img src={schoolshadow} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
        </div>

        <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(216 191 216 / 16%)'}}>
        <div className='col-lg-5'>
                <img src={behaviour} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
            <div className='col-lg-7' style={{ borderBottom: `3px solid ${borderBottomColor}`}} >
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-5'>Behavior therapy</p>
            <h3 style={{fontSize:'25px',color:'#41464bde'}}>"Rewriting patterns, embracing growth. Behavior therapy paves the way for positive change."</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Behavior therapy, a cornerstone of evidence-based practice in psychology, is a powerful approach focused on reshaping behaviors and fostering meaningful change. Through collaboration between the therapist and the individual, behavior therapy targets specific behaviors, their underlying causes, and the consequences they produce."</p>

                    <button className=' p-2' style={isMobile?mobText1:deskText1}>Start Now</button>
            </div>
           
        </div>

        <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(243 207 198 / 20%)'}}>
            <div className='col-lg-7'  style={{ borderBottom: `3px solid ${borderBottomColor}` }}>
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-5'>Speech Therapy</p>
            <h3 style={{fontSize:'30px',color:'#41464bde'}}>"Finding Your Voice, Embracing Your Potential."</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Speech therapy, a transformative journey of communication, holds the key to unlocking the boundless potential within each individual. Through tailored interventions and nurturing guidance, speech therapy empowers individuals to overcome speech and language challenges, fostering meaningful connections with others and enhancing self-expression. "</p>


                    <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button>
            </div>
            <div className='col-lg-5'>
                <img src={speechtherapy} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
        </div>

        <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(216 191 216 / 16%)'}}>
        <div className='col-lg-5'>
                <img src={grouptherapy} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
            <div className='col-lg-7' style={{ borderBottom: `3px solid ${borderBottomColor}` }}>
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-5'>Group therapy</p>
            <h3 style={{fontSize:'25px',color:'#41464bde'}}>"Healing Together, Thriving as One."</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"
Group therapy, a transformative and compassionate space, brings individuals together on a shared journey of healing and growth. In the supportive company of like-minded peers, participants find solace and understanding, knowing they are not alone in their struggles. Led by skilled therapists, group therapy sessions provide a platform for open expression, genuine connections, and profound insights."</p>

                    <button className=' p-2' style={isMobile?mobText1:deskText1}>Start Now</button>
            </div>
           
        </div>

        <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(243 207 198 / 20%)'}}>
            <div className='col-lg-7' style={{ borderBottom: `3px solid ${borderBottomColor}` }}>
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-5'>Academic Learning</p>
            <h3 style={{fontSize:'30px',color:'#41464bde'}}>"Unlocking Minds, Empowering Futures."</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Academic learning, a transformative pathway to knowledge, empowers individuals with the tools to unlock their full intellectual potential. Through tailored and dynamic approaches, academic learning fosters a love for learning and a thirst for discovery. In the nurturing environment of our programs, students are encouraged to explore, question, and excel in their educational pursuits. Our dedicated educators guide and inspire, igniting curiosity and critical thinking. "</p>


                    <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button>
            </div>
            <div className='col-lg-5'>
                <img src={academic} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
        </div>

        <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(216 191 216 / 16%)'}}>
        <div className='col-lg-5'>
                <img src={commu} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
            <div className='col-lg-7' style={{ borderBottom: `3px solid ${borderBottomColor}` }} >
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-5'>Communication Group</p>
            <h3 style={{fontSize:'25px',color:'#41464bde'}}>"Connecting Hearts, Healing Minds: Together in Group Therapy"</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Communication group therapy is a specialized form of therapeutic intervention that focuses on improving interpersonal communication skills and fostering meaningful connections among its participants. In this setting, individuals come together with the common goal of enhancing their communication abilities, which can encompass both verbal and non-verbal aspects of interaction."</p>

                    <button className=' p-2' style={isMobile?mobText1:deskText1}>Start Now</button>
            </div>
           
        </div>

        <div className='row  p-2 mt-3' style={{backgroundColor:'rgb(243 207 198 / 20%)'}}>
            <div className='col-lg-7' style={{ borderBottom: `3px solid ${borderBottomColor}` }}>
            <p style={{fontSize:'20px',color:'#653399'}} className='mt-5'>Special Education</p>
            <h3 style={{fontSize:'30px',color:'#41464bde'}}>"Nurturing Potential, Empowering Futures: Special Education Therapy"</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Special Education therapy is a compassionate and individualized approach to supporting and empowering individuals with diverse learning needs and abilities. This form of therapy is designed to address the unique challenges and strengths of each student, helping them overcome barriers and reach their full potential. "</p>


                    <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button>
            </div>
            <div className='col-lg-5'>
                <img src={special} style={{width:'100%',height:'350px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'5px'}} className='mt-2'/>
            </div>
        </div> */}


        
        
     
        </>
    )
}

export default Services
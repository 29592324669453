import React, {  useEffect, useState } from "react";
// import React, {Component, useContext, useEffect, useState } from "react"; unused component
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import vision from '../image/vision.png'
import mission from '../image/mission.png'
import diamond from '../image/diamond.png'
import healthcare from '../image/healthcare.png'
import ventures from '../image/ventures.png'


// import './Responsive.css'



var settings = {
  

  dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 2,

  //   speed: 5000,
  //   autoplaySpeed: 1000,
  // cssEase: "linear",

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
  ],
};
function Abt() {
  
  
 


  return (
    <>
      {/* <div
    className="d-none d-sm-block"> */}
      <div className='back ' >
      {/* <h2 className='p-3'>Our Technologies</h2> */}
     <center className='p-5'>
        <div className='row ' >
                  
            
                <Slider {...settings}>
                 
                <div >
    
   
    
       
        <img src={vision} style={{width:'120px',height:'120px'}}/>
        <p className='mt-2' style={{fontSize:'18px',color:'#653399',fontWeight:'bold'}}>OUR VISION</p>
        
       
        </div>
      <div >
        <img src={mission} style={{width:'120px',height:'120px'}}/>
        <p className='mt-2' style={{fontSize:'18px',color:'#653399',fontWeight:'bold'}}>OUR MISSION</p>
      </div>
      <div >
        <img src={diamond} style={{width:'120px',height:'120px'}}/>
        <p className='mt-2' style={{fontSize:'18px',color:'#653399',fontWeight:'bold'}}>VALUES</p>
      </div>
      <div >
        <img src={healthcare} style={{width:'120px',height:'120px'}}/>
        <p className='mt-2' style={{fontSize:'18px',color:'#653399',fontWeight:'bold'}}>HEALTH CARE</p>

      </div>
      <div >
        <img src={ventures} style={{width:'120px',height:'120px'}}/>
        <p className='mt-2' style={{fontSize:'18px',color:'#653399',fontWeight:'bold'}}>VENTURES</p>

      </div>
    
      
                </Slider>
                </div>
     
     </center>
 </div>
             
{/*           
      </div> */}
    </>
  );
}

export default Abt;
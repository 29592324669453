import React from "react";

import Footer from "./Footer";

import Section from "../components/Section";
import VisionHome from "../components/VisionHome";
import CurvedBanner from "./CurvedBanner";
import NewSec from "./NewSec";
import TherapyAbout from "../components/TherapyAbout";
import SecAbout from "../components/SecAbout";


const Home = () => {
  return (
    <>
    
      <NewSec />

      <CurvedBanner />

      <Section />

      <SecAbout />
      {/* <VisionHome /> */}

      {/* <TherapyAbout /> */}

      <Footer />
     
    </>
  );
};

export default Home;

import React from 'react'
// import yoga from './vedios/yoga.mp4'
// import yog from './vedios/yog.mp4'
import yoga from '../vedios/yoga.mp4'
import quote from '../image/quote.png'
import { isMobile } from 'react-device-detect'
import WaveMedia from '../base/WaveMedia'
import MediaPage2 from './Mediapage2'


const mobText = {border:'1px solid #D8BFD8',width:'50%',borderRadius:'0px',backgroundColor:'rgb(216 191 216 / 55%)',color:'#41464bde',fontSize:'18px'}
const deskText ={border:'1px solid #D8BFD8',width:'30%',borderRadius:'0px',backgroundColor:'rgb(216 191 216 / 55%)',color:'#41464bde',fontSize:'18px'}

const mobText1 = {border:'1px solid #F3CFC6',width:'50%',borderRadius:'0px',backgroundColor:'rgb(243 207 198 / 33%)',color:'#41464bde',fontSize:'18px'}
const deskText1 ={border:'1px solid #F3CFC6',width:'30%',borderRadius:'0px',backgroundColor:'rgb(243 207 198 / 33%)',color:'#41464bde',fontSize:'18px'}
const MediaPage = ()=>{
    const videoSource = 'https://www.youtube.com/watch?v=yMSmVzX6cy0';

    return(
        <>
        <WaveMedia/>
        <MediaPage2/>
        <div className='row mb-5'>
    <div className='col-lg-3'></div>
    <div className='col-lg-6'>
        <center>
            <img src={quote} style={{width:'80px'}}/>
        </center>
        <i className='mt-3' style={{color:'brown',fontSize:'20px'}}>Together, let us unravel the threads of healing, discovery, and growth, as we embark on this journey of transformation—one blog post at a time.</i>
        <p className='mt-3'>Whether you are seeking answers, inspiration, or simply a space to connect with others on a similar journey, our blog is here to accompany you. We invite you to explore the myriad topics 
            we present, knowing that each article is crafted with care and the hope that it may kindle a spark of insight or a newfound sense of strength within you.</p>
    </div>

    
</div>
        {/* <h1>Media Section</h1> */}
        {/* <div className='row mt-5 p-2' style={{backgroundColor:'rgb(243 207 198 / 20%)'}}>
            <div className='col-lg-6'>
            <video controls style={{width:'100%'}}>
        <source src={yoga} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
            </div>
            <div className='col-lg-6 '>
            <p style={{fontSize:'20px',color:'#41464bde'}} className='mt-2'>School Shadow</p>
            <h3 style={{fontSize:'30px',color:'#41464bde'}}>"Empowering Students through School Shadow Support"</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Navigating the educational environment can be challenging for some students. Our "School Shadow" service offers personalized support for students,
                 empowering them to thrive in their academic journey.. 
              "</p>

                    <button className=' p-2' style={isMobile?mobText:deskText}>Start Now</button>
            </div>

      
     
    </div>

    <div className='row mt-3 p-2' style={{backgroundColor:'rgb(216 191 216 / 16%)'}}>
    <div className='col-lg-6'>
    <p style={{fontSize:'20px',color:'#41464bde'}} >Behavior therapy</p>
            <h3 style={{fontSize:'25px',color:'#41464bde'}}>"Rewriting patterns, embracing growth. Behavior therapy paves the way for positive change."</h3>
                <center>
                    <hr style={{width:'50%'}}/>
                </center>
              
                <p className=' p-2' >"Behavior therapy, a cornerstone of evidence-based practice in psychology, is a powerful approach focused on 
                reshaping behaviors and fostering meaningful change. Through collaboration between the therapist and the individual."</p>

                    <button className=' p-2' style={isMobile?mobText1:deskText1}>Start Now</button>
            </div>
            <div className='col-lg-6'>
            <video controls style={{width:'100%'}}>
        <source src={yog} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
            </div>
           

      
     
    </div>
    <div className='row mt-5' >
        <div className='col-lg-3'> </div>
            <div className='col-lg-6'>
            <video controls style={{width:'100%'}}>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      
            </div>
            <center>                    <button className=' p-2' style={isMobile?mobText:deskText}>Open Youtube</button>
</center>
            
       
    </div> */}
    
        </>
    )
}

export default MediaPage


 import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nav1 from './components/Nav1';
import Home from './base/Home';
import MediaPage from './components/MediaPage';
import Contact from './components/Contact'
import Services from './components/Services';
import About from './components/About';
import Blog from './components/Blog';
import MobileSidebar from './base/MobileSidbar';
import WhatsApp from './base/WhatsApp';
import CallIcon from './base/CallIcon';


function App() {
    return (
        <div className="App">
        
                <BrowserRouter>
                <div
    className="d-none d-sm-block">
               <Nav1/>
               </div>
               <MobileSidebar/>
                                 
                   
                    <Routes>
                        <Route path="/" element={<Home/>}></Route>
                        <Route path="/media" element={<MediaPage/>}></Route>
                        <Route path="/contact" element={<Contact/>}></Route>
                        <Route path='/services' element={<Services/>}></Route>
                        <Route path='/about' element={<About/>}></Route>
                        <Route path='/blogs' element={<Blog/>}></Route>



                     



                    </Routes>
                    <WhatsApp/>
                    <CallIcon/>
                </BrowserRouter>
            
        </div>
    );
}

export default App;
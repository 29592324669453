import React from 'react';
import './CurvedBanner.css'; // Include your CSS file
import { BsArrowRight } from "react-icons/bs";
import skills from '../image/skills.png'
import hand from '../image/hand.png'
import edu from '../image/edu.png'
import { Link } from 'react-router-dom';


const CurvedBanner = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
   <>
   <section
  className="elementor-section elementor-top-section elementor-element elementor-element-c5773ea animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp"
  data-id="c5773ea"
  data-element_type="section"
  data-settings='{"animation":"fadeInUp"}'
>
  <div className="elementor-container elementor-column-gap-default">
    <div
      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-597f45e"
      data-id="597f45e"
      data-element_type="column"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-d8ac617 elementor-widget elementor-widget-heading"
          data-id="d8ac617"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h2 className="elementor-heading-title elementor-size-default" style={{color:'#653399'}}>
            Explore  Our Services
            </h2>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-d283939 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
          data-id="d283939"
          data-element_type="widget"
          data-settings='{"_animation":"fadeInUp"}'
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <h3 className="elementor-heading-title elementor-size-default">
              Your Happiness is An Essential
            </h3>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-aa71512 elementor-widget elementor-widget-text-editor"
          data-id="aa71512"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{color:'gray'}}>
            Our therapists are well trained. And always focus on children's development and help them to reach their goals
            </p>{" "}
          </div>
        </div>
        <section
          className="elementor-section elementor-inner-section elementor-element elementor-element-dddf78d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="dddf78d"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-99cc390 animated-slow animated fadeInLeft"
              data-id="99cc390"
              data-element_type="column"
              data-settings='{"animation":"fadeInLeft"}'
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-018377e elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="018377e"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                        <img style={{width:'90px',height:'90px',color:'#653399'}}
                          decoding="async"
                        
                          src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-1.png"
                          className="elementor-animation-grow attachment-full size-full wp-image-54"
                          alt=""
                          loading="lazy"
                          srcSet="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-1.png 300w, https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-1-150x150.png 150w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                          Behaviour Therapy
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                       In behavior therapy we teach children using techniques and protocols and help them understand the task or situation in better way...
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
               <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}} >Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
            <div 
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-82ab4d9 m-4"
              data-id="82ab4d9"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-3a03600 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="3a03600"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                      <img style={{width:'80px',height:'80px'}}
                          decoding="async"
                        //   width={300}
                        //   height={300}
                          src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-2.png"
                          className="elementor-animation-grow attachment-full size-full wp-image-62"
                          alt=""
                          loading="lazy"
                          srcSet="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-2.png 300w, https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-2-150x150.png 150w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                         
Speech therapy
                        </h4>
                        <p className="elementor-image-box-description" style={{color:'gray'}}>
                        The goal of speech therapy is to help children understand improve pronunciation, strengthening muscles, and learn to speak correctly...
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-77944ed animated-slow animated fadeInRight"
              data-id="77944ed"
              data-element_type="column"
              data-settings='{"animation":"fadeInRight"}'
            >
              <div className="elementor-widget-wrap elementor-element-populated" style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>

                <div
                  className="elementor-element elementor-element-f3dda56 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="f3dda56"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                      <img style={{width:'80px',height:'80px'}}
                          decoding="async"
                        //   width={300}
                        //   height={300}
                          src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-3.1.png"
                          className="elementor-animation-grow attachment-full size-full wp-image-64"
                          alt=""
                          loading="lazy"
                          srcSet="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-3.1.png 300w, https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-3.1-150x150.png 150w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                        Parent training
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                       Parent training is one the main programme we focus. Usually, parents find difficult to understand or work with children...
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
          </div>
        </section>

        <section
          className="mt-4 elementor-section elementor-inner-section elementor-element elementor-element-dddf78d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="dddf78d"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-99cc390 animated-slow animated fadeInLeft"
              data-id="99cc390"
              data-element_type="column"
              data-settings='{"animation":"fadeInLeft"}'
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-018377e elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="018377e"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                        <img style={{width:'90px',height:'90px',color:'#653399'}}
                         
                        
                          src={hand}
                         
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                     Play Therapy
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                         Play therapy is a remarkable and essential approach in the realm of child psychology, providing a unique and invaluable way for children to communicate, process emotions, and develop crucial life skills
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
               <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
            <div 
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-82ab4d9 m-4"
              data-id="82ab4d9"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-3a03600 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="3a03600"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                      <img style={{width:'90px',height:'90px',color:'#653399'}}
                         
                        
                         src={skills}
                        
                       />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                         
Motor Skills                        </h4>
                        <p className="elementor-image-box-description" style={{color:'gray'}}>
                        Motor skills are fundamental abilities that enable children to interact with their environment, develop physical coordination, and achieve various milestones crucial for their overall development.
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-77944ed animated-slow animated fadeInRight"
              data-id="77944ed"
              data-element_type="column"
              data-settings='{"animation":"fadeInRight"}'
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>

                <div
                  className="elementor-element elementor-element-f3dda56 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="f3dda56"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                      <img style={{width:'80px',height:'80px'}}
                         src={edu}
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                        School shadow
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                       This support is often one-on-one and aims to help the child successfully navigate the school environment and participate in educational activities alongside their peers
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
          </div>
        </section>


        <section
          className="elementor-section elementor-inner-section elementor-element elementor-element-0f3acf4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="0f3acf4"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-528ff59 animated-slow animated fadeInLeft mt-4"
              data-id="528ff59"
              data-element_type="column"
              data-settings='{"animation":"fadeInLeft"}'
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-7f23a24 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="7f23a24"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                      <img style={{width:'80px',height:'80px'}}
                          decoding="async"
                        //   width={300}
                        //   height={300}
                          src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-4.1.png"
                          className="elementor-animation-grow attachment-full size-full wp-image-69"
                          alt=""
                          loading="lazy"
                          srcSet="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-4.1.png 300w, https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-4.1-150x150.png 150w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                        School shadow training
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                       The main role of shadow teacher is to help the children to adjust to environment make them comfortable and help them understand classroom rules
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d5b8e1f m-4"
              data-id="d5b8e1f"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-332b2f3 elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="332b2f3"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                                            <img style={{width:'80px',height:'80px'}}

                          decoding="async"
                        //   width={300}
                        //   height={300}
                          src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-5.3.png"
                          className="elementor-animation-grow attachment-full size-full wp-image-70"
                          alt=""
                          loading="lazy"
                          srcSet="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-5.3.png 300w, https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-5.3-150x150.png 150w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                        Group therapy
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                       Group therapy main goal is to help the children to communicate and understand how to ask share and help others...
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-930e60e animated-slow animated fadeInRight mt-4"
              data-id="930e60e"
              data-element_type="column"
              data-settings='{"animation":"fadeInRight"}'
            >
              <div className="elementor-widget-wrap elementor-element-populated"  style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}>
                <div
                  className="elementor-element elementor-element-d29a4ce elementor-position-top elementor-widget elementor-widget-image-box"
                  data-id="d29a4ce"
                  data-element_type="widget"
                  data-widget_type="image-box.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image-box-wrapper">
                      <figure className="elementor-image-box-img">
                      <img style={{width:'80px',height:'80px'}}
                          decoding="async"
                        //   width={300}
                        //   height={300}
                          src="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-6.png"
                          className="elementor-animation-grow attachment-full size-full wp-image-75"
                          alt=""
                          loading="lazy"
                          srcSet="https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-6.png 300w, https://gtkit.rometheme.pro/genical/wp-content/uploads/sites/18/2023/02/Service-6-150x150.png 150w"
                          sizes="(max-width: 300px) 100vw, 300px"
                        />
                      </figure>
                      <div className="elementor-image-box-content">
                        <h4 className="elementor-image-box-title">
                        Occupational therapy
                        </h4>
                       <p className="elementor-image-box-description" style={{color:'gray'}}>
                       The main goal is to improve everyday skills which will help them to be more independent. It will also help in attention skills, self-care and learning strategies
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <b ><Link to='/services' onClick={scrollToTop} style={{color:'#653399'}}>Read More <span style={{color:'#653399',fontSize:'20px'}}><BsArrowRight/></span></Link></b>
              </div>
            </div>
          </div>
        </section>
      
      </div>
    </div>
  </div>
</section>

   </>
  );
};

export default CurvedBanner;

import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'
// import WhatsAppButton from './WhatsAppButton';
// import PopupForm1 from './PopupForm1';
// import PhoneCall from '../Anisys/PhoneCall';

const Footer = ()=>{
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return(
        <> 
        {/* <Chat/> */}
        {/* <PopupForm1/>
        <PhoneCall phoneNumber="+918123721201" />

         <WhatsAppButton /> */}
        {/* <ChatTheme/> */}
        <div style={{backgroundColor:'rgb(101 51 153 / 80%)',overflowX:'hidden'}}>
        
        <div className='row' >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
    <path fill="#fff" fill-opacity="1" d="M0,224L48,197.3C96,171,192,117,288,122.7C384,128,480,192,576,202.7C672,213,768,171,864,138.7C960,107,1056,85,1152,96C1248,107,1344,149,1392,170.7L1440,192L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
        </path></svg>
            <div className='col-lg-2'></div>
            <div className='col-lg-3 p-5'  style={{ textAlign: 'left' }}>
                <Link to='/' onClick={scrollToTop} className='textDec m-2'>Home</Link><br/>
                <Link to='/about' onClick={scrollToTop} className='textDec m-2'>About Us</Link><br/>
                <Link to='/services' onClick={scrollToTop} className='textDec m-2'>Services</Link><br/>
                <Link to='/media' onClick={scrollToTop} className='textDec m-2'>Media</Link><br/>
                <Link to='/contact' onClick={scrollToTop} className='textDec m-2'>Contact Us</Link>
            </div>
            <div className='col-lg-3  p-5'  style={{ textAlign: 'left' }}>
               
               <b className='textDec'>Working hours</b>
                
                <p className='text-white mt-1'>Monday to Friday :- <br/>9:30am to 5:00pm
              
               </p>
               <b className='textDec'>Office timings</b>
                
                <p className='text-white mt-1'>Monday to Saturday :-<br/> 9:00am to 6:00pm
              
               </p>
            </div>
            <div className='col-lg-3  p-5'  style={{ textAlign: 'left' }}>
                <b className='textDec'>Get In Touch-</b>
                
                <p className='text-white mt-1'>Mobile Number : 9008361473<br/><br/>
                <b> reach us on</b><br/>
                email : Ksowmya402@gmail.com</p>

                <b className='textDec'>Locations:-</b>
                
                <p className='text-white mt-1'>Raja Rajeshwari Nagar<br/>
                Arekere Bannerghatta road Hennur Kalyan Nagar
               </p>
            </div>
           
          
            <hr style={{color:'white',width:'50%',margin:'auto'}}/>
            <p className='text-white mt-3'> We help children in building skills in areas such as motor skills play, communication, understanding, school concepts, social skills, and academic skills.</p>
         
        </div>
        </div>
       
        </>
    )
}

export default Footer
import React from 'react'
import './SecAbout.css'
import { BsFillPeopleFill,BsFingerprint ,BsFastForwardFill} from "react-icons/bs";
import aboutim from '../image/aboutim.jpg'
import playing from '../image/playing.png'
import played from '../image/played.jpg'
import { isMobile } from 'react-device-detect';


const mobim={height:'300px',width:'100%'}
const deskim={height:'600px',width:'100%'}
const mobimg={width:'100%',height:'250px'}
const deskimg={width:'100%',height:'500px'}
const mobi={width:'400px',height:'150px'}
const deski={width:'400px',height:'200px'}
const mobsec={height:'500px',  backdropFilter: 'blur(20px)'}
const desksec={height:'200px',  backdropFilter: 'blur(20px)'}
const SecAbout = ()=>{
    return(
        <>
        
        <div
  data-elementor-type="wp-post"
  data-elementor-id={366}
  className="elementor elementor-366"
>

  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-4777ede elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="4777ede"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f7aafba"
        data-id="f7aafba"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-296d0d3 elementor-widget elementor-widget-heading"
            data-id="296d0d3"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}"
                }}
              />
              <h2 className="elementor-heading-title elementor-size-default">
            <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}>    About Shree Dhrithi PatashaalaOPC pvt ltd</i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-f3d8465 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="f3d8465"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
                We Are Here for You
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-1a65318 elementor-widget elementor-widget-text-editor"
            data-id="1a65318"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>Our team of licensed child therapists brings a wealth of experience and compassion to every session. Learn more about our therapists and their areas of expertise.</p>
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
At Shree Dhrithi Patashaala, we tailor our approach to each child's unique needs. We foster a warm and welcoming atmosphere, where children feel safe to express themselves and embark on a journey of growth.</p>
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
               we provide intervention services for children with Autism ADHD and slow learners. 
              We provide individual sessions and group sessions for children with needs. We work in a strictly 1:1 session. We ensure we help kids and help kidS reach maximum potential.

              </p>
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              We also conduct training programmes for students and parents who are interested to know about the field.
              </p>{" "}
            </div>
          </div>
          {/* <section
            className="elementor-section elementor-inner-section elementor-element elementor-element-12032df elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="12032df"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-609a14d"
                data-id="609a14d"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-70e13c0 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                    data-id="70e13c0"
                    data-element_type="widget"
                    data-widget_type="icon-box.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                          <span className="elementor-icon elementor-animation-">
                           <BsFillPeopleFill style={{color:'#653399'}}/>{" "}
                          </span>
                        </div>
                        <div className="elementor-icon-box-content">
                          <h4 className="elementor-icon-box-title">
                            <span>Expert Psychologist </span>
                          </h4>
                          <p className="elementor-icon-box-description" className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
                            Etiam posuere sapien ut sed vehicula aenean non.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4d538ab"
                data-id="4d538ab"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-2468123 elementor-position-left elementor-mobile-position-left elementor-view-default elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                    data-id={2468123}
                    data-element_type="widget"
                    data-widget_type="icon-box.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-icon">
                          <span className="elementor-icon elementor-animation-">
                           <BsFingerprint style={{color:'#653399'}}/>{" "}
                          </span>
                        </div>
                        <div className="elementor-icon-box-content">
                          <h4 className="elementor-icon-box-title">
                            <span>Confidentiality </span>
                          </h4>
                          <p className="elementor-icon-box-description" className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
                            Etiam posuere sapien ut sed vehicula aenean non.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <div
            className="elementor-element elementor-element-e563791 elementor-widget elementor-widget-text-editor" style={{color:'#653399'}}
            data-id="e563791"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <i style={{color:'black',fontSize:'22px'}}>
                “We help children in building skills in areas such as motor skills play, communication, understanding, school concepts, social skills, and academic skills.”
              </i>{" "}
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-19ccd47 animated-slow animated fadeInRight"  style={{color:'#653399'}}
        data-id="19ccd47"
        data-element_type="column"
        data-settings='{"animation":"fadeInRight"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}
            data-id="b601bcd"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
              <img
                // decoding="async"
                // width={800}
                // height={900}
src={aboutim}  style={isMobile?mobim:deskim}             
              />{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-bdc7a82 elementor-widget elementor-widget-spacer"
            data-id="bdc7a82"
            data-element_type="widget"
            data-widget_type="spacer.default"
          >
            <div className="elementor-widget-container">
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "/*! elementor - v3.15.0 - 02-08-2023 */\n.elementor-column .elementor-spacer-inner{height:var(--spacer-size)}.e-con{--container-widget-width:100%}.e-con-inner>.elementor-widget-spacer,.e-con>.elementor-widget-spacer{width:var(--container-widget-width,var(--spacer-size));--align-self:var(--container-widget-align-self,initial);--flex-shrink:0}.e-con-inner>.elementor-widget-spacer>.elementor-widget-container,.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer,.e-con>.elementor-widget-spacer>.elementor-widget-container,.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer{height:100%}.e-con-inner>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner,.e-con>.elementor-widget-spacer>.elementor-widget-container>.elementor-spacer>.elementor-spacer-inner{height:var(--container-widget-height,var(--spacer-size))}"
                }}
              />{" "}
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner" />
              </div>
            </div>
          </div>
          {/* <div
            className="elementor-element elementor-element-c2a22ef elementor-widget elementor-widget-elementskit-funfact"  style={{backgroundColor:'red'}}
            data-id="c2a22ef"
            data-element_type="widget"
            data-widget_type="elementskit-funfact.default"
          >
            <div className="elementor-widget-container">
              <div className="ekit-wid-con">
                <div className="elementskit-funfact text-center     ">
                  <div className="elementskit-funfact-inner position_top">
                    <div className="funfact-icon">
                      {" "}
                      <i />
                    </div>
                    <div className="funfact-content">
                      <div className="number-percentage-wraper">
                        <span
                          className="number-percentage"
                          data-value={13}
                          data-animation-duration={3500}
                          data-style="static"
                        >
                          13
                        </span>
                        <span className="super">Th</span>
                      </div>
                      <h3 className="funfact-title">Years Experience</h3>{" "}
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </section>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-b57e4b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="b57e4b3"
    data-element_type="section"
    data-settings='{"background_background":"classic"}'
  >
    <div className="elementor-background-overlay" />
    <div className="elementor-container elementor-column-gap-default">
     <div className='p-2' style={isMobile?mobsec:desksec} >
        <center>
            <h3 className='text-white mt-4'>
"Therapy is a journey of self-discovery, a bridge to healing, and a pathway to inner peace."</h3>
<b className='text-black' style={{textAlign:'center'}}>
Through therapy, we embark on a quest for healing, emboldened by the wisdom and guidance of a skilled practitioner. With each session, we confront our challenges, confront our fears, and gradually untangle the knots that have bound 
us. It's a process that invites us to embrace vulnerability, to lean into discomfort, and to rewrite the narratives that no longer serve us.
</b>
        </center>
     </div>
     
    
     
    </div>
  </section>
  <section
    className="elementor-section elementor-top-section elementor-element elementor-element-400bbe7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
    data-id="400bbe7"
    data-element_type="section"
  >
    <div className="elementor-container elementor-column-gap-default">
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8031c76 animated-slow animated fadeInLeft"
        data-id="8031c76"
        data-element_type="column"
        data-settings='{"animation":"fadeInLeft"}'
      >
        <div className="elementor-widget-wrap elementor-element-populated" >
          <div
 style={{border:'1px solid rgb(101 51 153 / 25%)',borderRadius:'5px',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px'}}            data-id="4df6451"
            data-element_type="widget"
            data-widget_type="image.default"
          >
            <div className="elementor-widget-container">
           
                 <img src={played} style={isMobile?mobimg:deskimg}
              />{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-f924706 elementor-widget elementor-widget-spacer"
            data-id="f924706"
            data-element_type="widget"
            data-widget_type="spacer.default"
          >
            <div className="elementor-widget-container">
              <div className="elementor-spacer">
                <div className="elementor-spacer-inner" />
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div
        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4ce8b0b"
        data-id="4ce8b0b"
        data-element_type="column"
      >
        <div className="elementor-widget-wrap elementor-element-populated">
          <div
            className="elementor-element elementor-element-7ebb03b elementor-widget elementor-widget-heading"
            data-id="7ebb03b"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
              <i style={{fontWeight:'bold',color:'#653399',fontSize:'22px'}}> 
              Why Choose  Shree Dhrithi PatashaalaOPC pvt ltd
              </i>
              </h2>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-b34f2d0 animated-slow elementor-widget elementor-widget-heading animated fadeInUp"
            data-id="b34f2d0"
            data-element_type="widget"
            data-settings='{"_animation":"fadeInUp"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h3 className="elementor-heading-title elementor-size-default" style={{fontWeight:'bold',fontSize:'32px',textAlign:'left'}}>
                Taking Care of Your Mental Health
              </h3>{" "}
            </div>
          </div>
          <div
            className="elementor-element elementor-element-5593f33 elementor-widget elementor-widget-text-editor"
            data-id="5593f33"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>
              Supporting children's mental health is a vital aspect of their growth and happiness. Encourage open communication, letting them know that it's okay to express their feelings. Listen without judgment and validate their emotions, helping them develop a healthy emotional vocabulary
              </p>{" "}
              <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}> Create a safe and supportive environment where they feel comfortable talking about
               their worries and fears. Teach them simple techniques like deep breaths or counting to ten to manage moments of stress or frustration
               . Engage in activities that promote emotional well-being, such as reading stories, drawing, or playing together. Establish a regular routine that 
               includes sufficient sleep, healthy meals, and physical activity to foster a balanced lifestyle.</p>
               <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>Expert Child Therapists: Our experienced therapists specialize in working with children, ensuring that each child receives the care and attention they deserve</p>
               {/* <p className=' p-2' style={{textAlign:'left',border:'1px solid white',backgroundColor:'white',boxShadow:'rgba(0, 0, 0, 0.1) 0px 30px 60px 0px',borderRadius:'10px'}}>Playful Approach: We harness the power of play to create a safe and engaging environment where children can express
                themselves, build resilience, and overcome challenges.</p> */}
            </div>
          </div>
          {/* <section
            className="elementor-section elementor-inner-section elementor-element elementor-element-7b153d3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="7b153d3"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1ec5c06"
                data-id="1ec5c06"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-83dc13c elementor-widget elementor-widget-heading"
                    data-id="83dc13c"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h4 className="elementor-heading-title elementor-size-default" style={{textAlign:'left'}}>
                        Our Vision
                      </h4>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-921e1a1 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="921e1a1"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <link
                        rel="stylesheet"
                        href="https://gtkit.rometheme.pro/genical/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css"
                      />{" "}
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                           <BsFastForwardFill style={{color:'purple'}}/>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            Aenean blandit euismod ipsum.
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                                 <BsFastForwardFill style={{color:'purple'}}/>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            Praesent suscipit fermentum.
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                                 <BsFastForwardFill style={{color:'purple'}}/>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            Vestibulum sit amet enim sit amet.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-7ae4dcd"
                data-id="7ae4dcd"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-6baf590 elementor-widget elementor-widget-heading"
                    data-id="6baf590"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h4 className="elementor-heading-title elementor-size-default" style={{textAlign:'left'}}>
                        Our Mission
                      </h4>{" "}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f9cf8bd elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="f9cf8bd"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                                 <BsFastForwardFill style={{color:'purple'}}/>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            Nullam fermentum ullamcorper et.
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                                 <BsFastForwardFill style={{color:'purple'}}/>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            Sed scelerisque tellus eu urna.
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                                 <BsFastForwardFill style={{color:'purple'}}/>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            Maecenas condimentum
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <div
            className="elementor-element elementor-element-79e171d elementor-widget elementor-widget-elementskit-progressbar"
            data-id="79e171d"
            data-element_type="widget"
            data-widget_type="elementskit-progressbar.default"
          >
            <div className="elementor-widget-container">
              <div className="ekit-wid-con">
                {" "}
                <div className="waypoint-tigger">
                  <div
                    className="skillbar-group tooltip-style"
                    data-progress-bar=""
                  >
                    <div className="single-skill-bar">
                      <div className="skill-bar-content">
                        <span className="skill-title">Confidentiality</span>
                      </div>
                     
                      <div className="skill-bar">
                        <div className="skill-track" style={{ width: "99%" }}>
                          <div className="number-percentage-wraper">
                            <span
                              className="number-percentage"
                              data-value={99}
                              data-animation-duration={3500}
                            >
                              99
                            </span>
                            %
                            <div className="svg-content">
                              <svg
                                version="1.1"
                                xmlns="https://www.w3.org/2000/svg"
                                xmlnsXlink="https://www.w3.org/1999/xlink"
                                preserveAspectRatio="none"
                                viewBox="0 0 116 79.6"
                              >
                                {" "}
                                <g>
                                  {" "}
                                  <path d="M0,18.3v21.3C0,49.8,8.2,58,18.3,58h5.9c7.8,0,15.3,3.1,20.8,8.6l13,13l13-13c5.5-5.5,13-8.6,20.8-8.6h5.9 c10.1,0,18.3-8.2,18.3-18.3V18.3C116,8.2,107.8,0,97.7,0H18.3C8.2,0,0,8.2,0,18.3z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                   
                  </div>
                 
                </div>
              </div>{" "}
            </div>
          </div> */}
          {/* <div
            className="elementor-element elementor-element-27373b4 elementor-widget elementor-widget-elementskit-progressbar"
            data-id="27373b4"
            data-element_type="widget"
            data-widget_type="elementskit-progressbar.default"
          >
            <div className="elementor-widget-container">
              <div className="ekit-wid-con">
                {" "}
                <div className="waypoint-tigger">
                  <div
                    className="skillbar-group tooltip-style"
                    data-progress-bar=""
                  >
                    <div className="single-skill-bar">
                      <div className="skill-bar-content">
                        <span className="skill-title">Therapy</span>
                      </div>
                      <div className="skill-bar">
                        <div className="skill-track" style={{ width: "95%" }}>
                          <div className="number-percentage-wraper">
                            <span
                              className="number-percentage"
                              data-value={95}
                              data-animation-duration={3500}
                            >
                              95
                            </span>
                            %
                            <div className="svg-content">
                              <svg
                                version="1.1"
                                xmlns="https://www.w3.org/2000/svg"
                                xmlnsXlink="https://www.w3.org/1999/xlink"
                                preserveAspectRatio="none"
                                viewBox="0 0 116 79.6"
                              >
                                {" "}
                                <g>
                                  {" "}
                                  <path d="M0,18.3v21.3C0,49.8,8.2,58,18.3,58h5.9c7.8,0,15.3,3.1,20.8,8.6l13,13l13-13c5.5-5.5,13-8.6,20.8-8.6h5.9 c10.1,0,18.3-8.2,18.3-18.3V18.3C116,8.2,107.8,0,97.7,0H18.3C8.2,0,0,8.2,0,18.3z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div> */}
          {/* <div
            className="elementor-element elementor-element-c9efd72 elementor-widget elementor-widget-elementskit-progressbar"
            data-id="c9efd72"
            data-element_type="widget"
            data-widget_type="elementskit-progressbar.default"
          >
            <div className="elementor-widget-container">
              <div className="ekit-wid-con">
                {" "}
                <div className="waypoint-tigger">
                  <div
                    className="skillbar-group tooltip-style"
                    data-progress-bar=""
                  >
                    <div className="single-skill-bar">
                      <div className="skill-bar-content">
                        <span className="skill-title">Counseling</span>
                      </div>
                      <div className="skill-bar">
                        <div className="skill-track" style={{ width: "97%" }}>
                          <div className="number-percentage-wraper">
                            <span
                              className="number-percentage"
                              data-value={97}
                              data-animation-duration={3500}
                            >
                              97
                            </span>
                            %
                            <div className="svg-content">
                              <svg
                                version="1.1"
                                xmlns="https://www.w3.org/2000/svg"
                                xmlnsXlink="https://www.w3.org/1999/xlink"
                                preserveAspectRatio="none"
                                viewBox="0 0 116 79.6"
                              >
                                {" "}
                                <g>
                                  {" "}
                                  <path d="M0,18.3v21.3C0,49.8,8.2,58,18.3,58h5.9c7.8,0,15.3,3.1,20.8,8.6l13,13l13-13c5.5-5.5,13-8.6,20.8-8.6h5.9 c10.1,0,18.3-8.2,18.3-18.3V18.3C116,8.2,107.8,0,97.7,0H18.3C8.2,0,0,8.2,0,18.3z" />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </section>


 
</div>

        </>
    )
}

export default SecAbout